"use client";
import ErrorCustom from "~components/customPages/ErrorCustom";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <html>
      <body>
        <ErrorCustom from="global" error={error} reset={reset} />;
      </body>
    </html>
  );
}
